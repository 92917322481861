<template>
  <div v-if="dialog">
    <v-dialog
        :value="dialog"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> កែប្រែភាគរយ PAYOUT</v-toolbar-title>
          <v-spacer></v-spacer>
             <v-toolbar-items>
            <v-btn
                v-if="loading"
                disabled
                text
                @click="validate"
            >
            {{ $t('button.update')}}
            </v-btn>
            <v-btn
                v-else
                text
                @click="validate"
            >
             {{ $t('button.update')}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading"/>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row class="mt-5">
                <v-text-field
                    v-model="selectKdPayout.target_percentage"
                    label="Target ( គោលដៅ )"
                    :rules="rules.target_percentage"
                    type="number"
                />
              </v-row>
              <v-row >
                  <v-text-field
                    v-model="selectKdPayout.meron_percentage"
                    label="Meron Percentage ( ភាគរយក្រហម )"         
                    :rules="rules.rule_meron_percentage"
                    type="number"
                ></v-text-field>
                 <!-- @keyup="calculateWala" -->
              </v-row>
                <v-row class="mt-5">
                <v-text-field
                    v-model="selectKdPayout.wala_percentage"
                    label="Meron Percentage ( ភាគរយខៀវ )"         
                    :rules="rules.rule_wala_percentage"
                    type="number"
                />
                <!-- @keyup="calculateMeron" -->
              </v-row>

                   <v-row class="mt-5">
                   <v-select
                :items="[1, 2,3,4]"
                v-model="selectKdPayout.channel_type"
                label="Select Channel"
              />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean
    },
    selectKdPayout: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: function () {
        return {
          wala_percentage: [
            v => !!v || 'Name is required',
          ],
          meron_percentage: [
            v => !!v || 'description is required',
          ],
          target_percentage: [
            (v) => {
          if (
            !isNaN(parseFloat(v)) &&
            v > 0 &&
            !!v
          )
            return true;
          return "Number has to be bigger than 0";
        }
          ],
          rule_meron_percentage: [(v) => {
          if (
            !isNaN(parseFloat(v)) &&
            v > 0 &&
            v <= this.objKdPayout.target_percentage - this.objKdPayout.wala_percentage &&
            !!v
          )
            return true;
          return "Number has to be bigger than 0 and smaller or equal target"  + this.selectKdPayout.target_percentage;
        }],
        rule_wala_percentage: [(v) => {
          if (
            v > 0 &&
            v <= this.objKdPayout.target_percentage - this.objKdPayout.meron_percentage &&
            !!v
          )
          return true;
          return "Number has to be bigger than 0 and smaller or equal target " + this.selectKdPayout.target_percentage;
        },]
        }
      }
    },
    isValidate: {
      type: Boolean,
      default: true
    },
    
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate
      },
      // setter
      set: function (newValue) {
        this.$emit('update:isValidate', newValue)
      }
    }
  },
  data: () => {
    return {
      showPassword: false,
      items:[
        { _id:1,
          name:"លុយដុល្លា - Usd"
        },
        { _id:2,
          name:"លុយរៀល - Khmer"
        },
        { _id:3,
          name:"លុយបាត - Baht"
        },
        ],
    }
  },
  //TRACK CHANGE
  // watch: {
  // },
  methods: {
    // calculateWala() {
    //   this.selectKdPayout.wala_percentage =
    //     this.selectKdPayout.target_percentage - this.selectKdPayout.meron_percentage;
    // },
    // calculateMeron() {
    //   this.selectKdPayout.meron_percentage =
    //     this.selectKdPayout.target_percentage - this.selectKdPayout.wala_percentage;
    // },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
      }
    },
  }
}

</script>
